export const landingQuery = `
*[_type == "landingpage"]{
  description,
  enable
}[0]
`;

export const homeQuery = `*[_type == "home"]{
    footer, privacy, algemene, "intro": intro.intro.asset->url,
    "teamImage": *[_type == "team"][0].img.img.asset->url
  }[0]
  `;

export const verhaalQuery = `
  *[_type == "verhaal"]{
      description, "image":image.image.asset->url, "mobiel":mobiel.mobiel.asset->url,
  }[0]
  `;

export const contactQuery = `
  *[_type == "contact"]{
    "header" : header.header.asset->url,
      description, post
  }[0]
  `;

export const vacaturesQuery = `
  *[_type == "vacatures"]{
      description
  }[0]
  `;

export const premieQuery = `
  *[_type == "premies"]{
    title,
      description,
      items[]{
        title,
        description,
        url
      } 
}
  `;

export const projectQuery = `
  *[_type == "project"]{
    title,
      "header" : header.header.asset->url,
      client,
      site,
      description,
      "images" : imagessub[]{
        "url" : img.asset->url,
        description
      }
}
  `;

export const techniekQuery = `
  *[_type == "techniek"]{
    title,
    _id,
    description,
    "project" : projects->{
      title,
      "header" : header.header.asset->url,
      client,
      site,
      description,
      "images" : imagessub[]{
        "url" : img.asset->url,
        description
      }
    }
}
  `;

// "img" : img.asset->url,
