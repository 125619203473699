import sanityClient from "@sanity/client";
import envi from "./environment";
import settings from "./connector_settings.json";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer";
import imageUrlBuilder from "@sanity/image-url";
import {
  contactQuery,
  homeQuery,
  landingQuery,
  premieQuery,
  projectQuery,
  techniekQuery,
  vacaturesQuery,
  verhaalQuery,
} from "./queries";
const prevFetched = {};

export const client = (dispatch) => {
  const environment = envi();
  let _ = settings.settings;
  let news = [];
  const sc = sanityClient({
    projectId: _.projectId,
    dataset: _.dataset,
    token: _.token, // or leave blank to be anonymous user
    ignoreBrowserTokenWarning: true,
    useCdn: false,
    apiVersion: "2021-08-31",
  });

  const fetch = (query) => {
    return new Promise((resolve, reject) => {
      if (prevFetched[query]) {
        if (environment.dev) {
          console.log("reused cached query");
        }
        resolve(prevFetched[query]);
      } else {
        sc.fetch(query)
          .then((data) => {
            prevFetched[query] = data;
            resolve(data);
          })
          .catch(reject);
      }
    });
  };

  const builder = imageUrlBuilder(sc);

  const squareImage = (url, width) => {
    return builder.image(url).width(width).height(width).url();
  };

  if (environment.dev) {
    environment.printstatus();
  }

  const generateQuery = (query) => {
    return new Promise((resolve, reject) => {
      fetch(query).then((data) => {
        resolve(data);
      });
    });
  };

  const getData = () => {
    return [
      generateQuery(landingQuery),
      generateQuery(homeQuery),
      generateQuery(verhaalQuery),
      generateQuery(contactQuery),
      generateQuery(premieQuery),
      generateQuery(projectQuery),
      generateQuery(techniekQuery),
      generateQuery(vacaturesQuery),
    ];
  };

  return {
    fetch,
    environment,
    getData,
  };
};

export default client;
