import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  lightblue,
  LeadingText,
  LeadingTextBlock,
  blue,
  ResponsiveImage,
  TextBlock,
  ImageWithCaption,
  lightyellow,
  mustardyellow,
} from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { Container, Grid } from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import Landing from "./Landing";

// TODO: implement

export const VacaturesContent = () => {
  const vacatures = useSelector((state) => state.data.vacatures);

  if (!vacatures) {
    return null;
  }

  return (
    <div style={{ background: lightblue }}>
      <Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={2}>
          {/* Adjust the grid item sizes as necessary for your design */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Title color={blue}>Vacatures</Title>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} />
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <LeadingTextBlock
              color={blue}
              description={vacatures.description}
            />
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </div>
  );
};

const Vacatures = () => {
  const vacatures = useSelector((state) => state.data.vacatures);

  const Meta = (
    <MetaTags>
      <title>{"MEEtec vacatures"}</title>
      <meta
        name="description"
        content={"Mechelse Energie Technieken - Vacatures"}
      />
      <meta property="og:title" content={"MEEtec vacatures"} />
    </MetaTags>
  );

  if (!vacatures) {
    return (
      <>
        {Meta}
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {Meta}
        <br />
        <br />
        <br />
        <div style={{ minHeight: "100vh", background: lightblue }}>
          <VacaturesContent />
        </div>
      </>
    );
  }
};

export default Vacatures;
