import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "./Home";
import Landing from "./Landing";
import Onserverhaal from "./Onsverhaal";
import Contact from "./Contact";
import NotFound from "./404";
// import Members from "./members"
import theme, { blue } from "../theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import sanityclient from "../client";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer";
// import Winterschool from "./Winterschool";
import Onsverhaal from "./Onsverhaal";
import Header from "../components/header";
import Footer from "../components/footer";
import Builder from "../theme/builder";
import Technieken from "./Technieken";
import Premies from "./Premies";
import Algemene from "./Algemene";
import Privacy from "./Privacy";
import ContactPost from "./ContactPost";
import Vacatures from "./Vacatures";
import Team from "./Team";

// // import themer
// // import './App.css';
// /*

// TODO:
//   - bring in client
// - make project page
// - make member page
// - make

// */

const DataHelper = () => {
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch) {
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli });
    }

    if (data.client && !data.home) {
      Promise.all(data.client.getData()).then((values) => {
        dispatch({ type: DATA_ACTIONS.SET_LANDING, landing: values[0] });
        dispatch({ type: DATA_ACTIONS.SET_HOME, home: values[1] });
        dispatch({ type: DATA_ACTIONS.SET_VERHAAL, verhaal: values[2] });
        dispatch({ type: DATA_ACTIONS.SET_CONTACT, contact: values[3] });
        dispatch({ type: DATA_ACTIONS.SET_PREMIE, premie: values[4] });
        dispatch({ type: DATA_ACTIONS.SET_PROJECTS, projects: values[5] });
        dispatch({ type: DATA_ACTIONS.SET_TECHNIEKEN, technieken: values[6] });
        dispatch({ type: DATA_ACTIONS.SET_VACATURES, vacatures: values[7] });
      });
    }
  });
  return null;
};

function Meetec() {
  const history = createBrowserHistory();
  return (
    <BrowserRouter history={history}>
      <div style={{ backgroundColor: blue, minHeight: "100vh" }}>
        <DataHelper />
        <ThemeProvider theme={theme}>
          <Header />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/premies" element={<Premies />} />
            <Route path="/vacatures" element={<Vacatures />} />
            <Route path="/technieken" element={<Technieken />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/kontact" element={<Contact />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/onsverhaal" element={<Onsverhaal />} />
            <Route path="/verhaal" element={<Onsverhaal />} />
            <Route path="/builder" element={<Builder />} />
            <Route path="/algemenevoorwaarden" element={<Algemene />} />
            <Route path="/privacybeleid" element={<Privacy />} />
            <Route path="/contactpost" element={<ContactPost />} />
            <Route path="/team" element={<Team />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default Meetec;
