import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  lightblue,
  LeadingText,
  LeadingTextBlock,
  blue,
  ResponsiveImage,
  TextBlock,
  ImageWithCaption,
  lightyellow,
} from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import Landing from "./Landing";

// TODO: implement

export const TechniekenContent = () => {
  const technieken = useSelector((state) => state.data.technieken);
  const [techniek, setTechniek] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Detect xs and sm

  useEffect(() => {
    if (!techniek && technieken && technieken.length) {
      setTechniek(technieken[0]);
    }
  });

  if (!techniek || !technieken) {
    return null;
  }

  return (
    <div style={{ background: blue }}>
      <Container>
        <br />
        <br />
        <Grid container spacing={2}>
          {/* Sticky Menu for md and larger */}
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            style={{
              position: isSmallScreen ? "static" : "sticky",
              top: isSmallScreen ? "unset" : "20px",
              height: "fit-content",
            }}
          >
            <Title color={lightblue}>Technieken</Title>
            <br />
            <br />
            {technieken.map((t) => (
              <div
                className="interacter"
                key={t.title}
                style={{ marginBottom: "10px", cursor: "pointer" }}
                onClick={() => {
                  setTechniek(t);
                }}
              >
                <SubTitle color={lightblue}>
                  <span
                    style={{
                      textDecoration:
                        techniek.title === t.title ? "underline" : "",
                    }}
                  >
                    {t.title}
                  </span>
                </SubTitle>
              </div>
            ))}
          </Grid>

          {/* Content */}
          <Grid item xs={0} sm={0} md={2} lg={2} />
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <LeadingTextBlock
              color={lightblue}
              description={techniek.description}
            />
            {techniek.project ? (
              <div>
                <br />
                <SubTitle color={lightblue}>{techniek.project.title}</SubTitle>
                <br />
                <ResponsiveImage src={techniek.project.header} />
                <br />
                <TextBlock
                  color={lightblue}
                  description={techniek.project.description}
                />
                <br />
                <SubTitle color={lightyellow}>
                  {techniek.project.client}
                </SubTitle>
                <br />
                <TextBlock
                  color={lightyellow}
                  description={techniek.project.site}
                />
                <br />
                {techniek.project.images.map(({ url, description }) => (
                  <ImageWithCaption key={url} src={url} caption={description} />
                ))}
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Technieken = () => {
  const landing = useSelector((state) => state.data.landing);
  const verhaal = useSelector((state) => state.data.verhaal);

  const Meta = (
    <MetaTags>
      <title>{"MEEtec technieken"}</title>
      <meta
        name="description"
        content={"Mechelse Energie Technieken - Technieken"}
      />
      <meta property="og:title" content={"MEEtec technieken"} />
    </MetaTags>
  );

  if (!landing || !verhaal) {
    return (
      <>
        {Meta}
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {Meta}
        <br />
        <br />
        <br />
        <br />
        <TechniekenContent />
      </>
    );
  }
};

export default Technieken;
